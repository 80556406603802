import React from 'react'

const Header = () => {
  const  baseUrl= "https://events.thevillalife.com"
  // const  baseUrl= "http://localhost:3001"
  return (
    <header className="header">
    <div className="container">
      <div className="header-content">
        <div className="logo-container">
            <a href="https://thevillalife.com" className="styled__Logo-sc-avs4if-2 giuUEp">
              <img className=" h-5 w-auto" src="https://villalife-prod.s3.amazonaws.com/dark_logo.svg" loading="lazy" alt="" width="auto" height="20px"/>
            </a>       
         </div>
        <nav className="nav">
          <a href={baseUrl}>Home</a>
          <a href={`${baseUrl}/corporate-retreats`}>Corporate Retreats</a>
          <a href={`${baseUrl}/weddings`} active>Weddings</a>
          <a href={`${baseUrl}/contact`} active>Contact</a>
          {/* <a href={`${baseUrl}/dashboard`}>Dashboard</a> */}
          {/* <a href={`${baseUrl}/preferences`}>Preferences</a> */}
          
        </nav>
       
      </div>
    </div>
  </header>
  )
}

export default Header